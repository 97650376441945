/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'
import { getAccessibleModules } from '../../../../../global.d'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser }: any = useAuth();
  
  const roleWiseModuleAccess = getAccessibleModules(currentUser);
  return (
    <>
      {(roleWiseModuleAccess.includes('dashboard')) && (
        <SidebarMenuItem
          to='/dashboard'
          icon='element-11'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
      )}


      {(roleWiseModuleAccess.includes('requests')) && (
        <SidebarMenuItem
          to='/request'
          icon='bi bi-file-earmark-text'
          title={'Requests'}
        />
      )}
      {roleWiseModuleAccess.includes('member') &&
        <>
          <SidebarMenuItemWithSub
            to='/members'
            title='Member Management'
            icon='bi bi-people-fill'
          //fontIcon='bi-person'
          >
            <SidebarMenuItem to='/members' title='List Members' hasBullet={true} />
            {currentUser.role != 'administrator' ?
              <>
                <SidebarMenuItem to='/members/create' title='Create Member' hasBullet={true} />
                <SidebarMenuItem to='/members/import' title='Import Members' hasBullet={true} />
              </> : ""
            }
          </SidebarMenuItemWithSub>
        </>
      }
      {roleWiseModuleAccess.includes('property') &&
        <SidebarMenuItemWithSub
          to='/property'
          title='Property Management'
          icon='bi bi-send-fill'
        >
          <SidebarMenuItem to='/property' title='List Property' hasBullet={true} />
          {currentUser.role != 'administrator' ?
            <>
              <SidebarMenuItem to='/property/create' title='Create Property' hasBullet={true} />
              <SidebarMenuItem to='/property/import' title='Import Property' hasBullet={true} />
            </> : ""}
        </SidebarMenuItemWithSub>
      }
      {roleWiseModuleAccess.includes('hoa') &&

        <SidebarMenuItemWithSub
          to='/hoa'
          title='HOA Management'
          icon='bi bi-person-fill'
        >
          <SidebarMenuItem to='/hoa' title='List HOA' hasBullet={true} />
          <>
            <SidebarMenuItem to='/hoa/create' title='Create HOA' hasBullet={true} />
          </>

        </SidebarMenuItemWithSub>
      }
      {roleWiseModuleAccess.includes('role') &&

        <SidebarMenuItemWithSub
          to='/staff'
          title='Roles'
          icon="bi bi-clipboard-check-fill"
          fontIcon='bi-person'
        >
          <SidebarMenuItem to='/staff' title='View Roles' hasBullet={true} />
          {/* <SidebarMenuItem to='/staff/create' title='Create Staff' hasBullet={true} /> */}
          <SidebarMenuItem to='/staff/roles' title='Roles List' hasBullet={true} />
        </SidebarMenuItemWithSub>
      }


      {roleWiseModuleAccess.includes('guideline') &&
        <SidebarMenuItemWithSub
          to='/guidelines'
          title='Guideline Management'
          icon='bi bi-list-check'
        >
          <SidebarMenuItem to='/guidelines' title='List Guidelines' hasBullet={true} />
          {(currentUser.role != 'administrator' && !currentUser.assignRole.includes('review_board_member')) ? <SidebarMenuItem to='/guidelines/create' title='Create Guidelines' hasBullet={true} /> : ""}

        </SidebarMenuItemWithSub>
      }
      {roleWiseModuleAccess.includes('acknowledgement') &&
        <>
          <SidebarMenuItemWithSub
            to='/acknowledgement'
            title='Acknowledgement'
            icon='bi bi-check-circle-fill'
          >
            <SidebarMenuItem to='/acknowledgement' title='List Acknowledgement' hasBullet={true} />
            {currentUser.role != 'administrator' &&
              <SidebarMenuItem to='/acknowledgement/create' className='mx-0' title='Create Acknowledgement' hasBullet={true} />
            }
          </SidebarMenuItemWithSub>

        </>
      }

      {roleWiseModuleAccess.includes('settings') &&
        <SidebarMenuItem
          to='/account/settings'
          icon='bi bi-gear-fill'
          title="Settings"
        />
      }
      {roleWiseModuleAccess.includes('modify_request_acknowledgement') &&
        <SidebarMenuItem
          to='/modification-acknowledge'
          icon='bi bi-gear-fill'
          title="Modification Request Acknowledge"
        />
      }
    </>
  )
}
export { SidebarMenuMain }
