import { KTCard } from '../../../../_metronic/helpers'
import { AcknowledgementTable } from './table/AcknowledgementTable'
import { AcknowledgementListHeader } from './components/AcknowledgementListHeader'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
const AcknowledgementList = () => {
  return (
    <>
      <KTCard>
        <AcknowledgementListHeader />
        <AcknowledgementTable />
      </KTCard>
    </>
  )
}

const AcknowledgementListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <AcknowledgementList />
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { AcknowledgementListWrapper }
