
function Address({ address = "", city = "", state = "", zip = "" }) {
    const fullAddress = `${address}, ${city}, ${state}, ${zip}`;
    return <div>{fullAddress}</div>;
}
// export { Address }


function FormateAddress({ address = "", city = "", state = "", zip = "" }) {
    return `${address}, ${city}, ${state}, ${zip}`;
}
export { Address, FormateAddress }
