
import axios, { AxiosResponse } from 'axios'
export * from './HeaderWrapper'
const API_URL = process.env.REACT_APP_API_URL
const HOA_UPDATE_URL = `${API_URL}/admin/update-selected-hoa`

// export function hoaUpdate(selected_hoa:any) {

//     return axios.patch<any>(HOA_UPDATE_URL, {
//         selected_hoa
//     })
// }
export async function hoaUpdate(selected_hoa: any): Promise<null> {
    try {
        const response: AxiosResponse<any> = await axios.patch(HOA_UPDATE_URL, {selected_hoa});
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('API Error:', error.response?.data || error.message);
        } else {
            console.error('Unexpected Error:', error);
        }
        return null;
    }
}