import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { initialQueryState, KTIcon, useDebounce } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { useAuth } from '../../../auth'

const AcknowledgementListHeader = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({ search: debouncedSearchTerm, ...initialQueryState })
      }
    },
    [debouncedSearchTerm]
  )
  const { currentUser }: any = useAuth();
  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-title'>
        <div className='d-flex align-items-center position-relative my-1 me-3'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search Acknowledgement'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <a href='#' className='btn btn-primary' onClick={() => setSearchTerm('')}>Reset</a>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          {currentUser.role != 'administrator' ?
            <Link to={"/acknowledgement/create"} className='btn btn-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Acknowledgement
            </Link> : ""}

        </div>
      </div>
    </div>
  )
}

export { AcknowledgementListHeader }
