import { useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from '../table/columns/CustomHeaderColumn'
import { CustomRow } from '../table/columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import AcknowledgementColumns from './columns/_columns'
import { Acknowledgement } from '../../core/_models'
import { ListLoading } from '../../../../common/ListLoading'
import { AcknowledgementListPagination } from '../components/AcknowledgementListPagination'
import { KTCardBody } from '../../../../../_metronic/helpers'
import TableSkeleton from '../../../../common/Skeleton/TableSkeleton'
import { useAuth } from '../../../auth'
import { Link } from 'react-router-dom'
import { AcknowledgementCustomHeader } from './columns/AcknowledgementCustomHeader'

const AcknowledgementTable = () => {
  const { currentUser }: any = useAuth()
  const acknowledgement = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => acknowledgement, [acknowledgement])
  // const columns = useMemo(() => AcknowledgementColumns, [])
  const columns = useMemo(() => {
    let modifiedColumns = [...AcknowledgementColumns];

    if (currentUser?.role === 'administrator') {
      const dynamicColumn = {
        Header: (props) => (
          <AcknowledgementCustomHeader
            tableProps={props}
            title="HOA Name"
            className="min-w-150px"
          />
        ),
        id: 'official_name', 
        //accessor: (row) => row.member?.official_name || '', // Safe accessor
        Cell: ({ ...props }) => (
          <Link
            to={`/hoa/${props.data[props.row.index].member.id}/details`}
            className="text-dark text-hover-primary fs-6 mb-1"
          >
            {props.data[props.row.index].member.official_name}
          </Link>
        )
      };
      modifiedColumns.splice(2, 0, dynamicColumn);
      modifiedColumns = modifiedColumns.filter((col) => col.id !== 'actions');
    }
    return modifiedColumns;

  }, [currentUser]);

  // const [columns, setColumns] = useState(AcknowledgementColumns());
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Acknowledgement>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {isLoading ? (
              <>
                {currentUser.role.includes("hoa") ? <TableSkeleton rows={7} columns={7} /> : <TableSkeleton rows={7} columns={6} />}

              </>
            ) : rows.length > 0 ? (
              rows.map((row: Row<Acknowledgement>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <AcknowledgementListPagination />
      {/* {isLoading && <ListLoading />} */}
    </KTCardBody>
  )
}

export { AcknowledgementTable }
