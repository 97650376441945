import { FC } from 'react'
import { Guideline } from '../../../core/_models'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../auth'

type Props = {
  guideline: Guideline
}

const GuidelineInfoCell: FC<Props> = ({ guideline }) => {
  const { currentUser } = useAuth();
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <Link to={currentUser?.role === 'administrator' || currentUser?.assignRole.includes('review_board_member') ? "#" : (`/guidelines/${guideline.id}/edit`)} className='text-gray-800 text-hover-primary mb-1'>
          {guideline.request_item}
        </Link>
      </div>
    </div>
  )
}
export { GuidelineInfoCell }
