import clsx from "clsx";
import { FormikHelpers, useFormik } from "formik";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup'
import { IBoardCreate, boardInitValues as initialValues } from "./core/_models";
import { createBoard, searchMember } from "./core/_requests";
import { toast } from "react-toastify";
import Select from 'react-select';
import { getSelectStyles, ROLE_OPTIONS } from "../../../global.d";

const boardCreateSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required').email('Please enter valid email formate'),
    // password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long')
    //     .matches(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, 'Password must match the required pattern'),
    password: Yup.string().nullable(),
    //business_address: Yup.string().nullable('Business address is required'),
    role: Yup.array().min(1, 'Selection is required').nullable(),

})
const BoardCreate: FC = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate();
    const [emailSuggestions, setEmailSuggestion] = useState<any[]>([])
    const [passwordVisible, setPasswordVisible] = useState(true);
    const [existingMember, setExistingMember] = useState(false);
    const isDarkMode = localStorage.getItem('kt_theme_mode_menu')
    const { handleSubmit, touched, errors, getFieldProps, isSubmitting, isValid, setFieldValue, setTouched,
        setErrors } = useFormik<IBoardCreate>({
            initialValues: initialValues,
            validationSchema: boardCreateSchema,

            onSubmit: async (values: IBoardCreate, { setStatus, setSubmitting }: FormikHelpers<IBoardCreate>) => {
                setLoading(true)
                setError(null)
                try {
                    values.existing_member = existingMember;
                    const roleArrData: any = values.role ?? [];
                    const roleArr = roleArrData.map(item => item.value);
                    values.role = roleArr;
                    const data: any = await createBoard(values);

                    if (data.status) {
                        setLoading(false)
                        setSubmitting(false);
                        toast.success('Staff created successfully.')
                        navigate('/staff');
                    }
                    // else {
                    //     toast.error(data.error);
                    //     setSubmitting(false)
                    //     setLoading(false)
                    // }
                } catch (error) {
                    // toast.error('Failed to create board. Please try again.')
                    // setStatus('Failed to create board')
                    setSubmitting(false)
                    setLoading(false)
                }
            },
        })
    const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;

        setFieldValue("email", email);
        setExistingMember(false);
        if (email.length >= 2) {
            try {
                const suggestions: any = await searchMember(email);
                setEmailSuggestion(suggestions && Array.isArray(suggestions.data) ? suggestions.data : [])
            } catch (error) {
                console.error("Failed to fetch email suggestions", error);
                setEmailSuggestion([]);
            }
        } else {
            setEmailSuggestion([]);
        }
    };
    const handleEmailSelect = (user: any) => {
        setTouched({
            email: false,
            first_name: false,
            last_name: false
        });
        setErrors({
            email: undefined,
            first_name: undefined,
            last_name: undefined
        });
        setEmailSuggestion([]);
        setPasswordVisible(false)
        setFieldValue("email", user.email)
        setFieldValue("first_name", user.first_name)
        setFieldValue("last_name", user.last_name)
        setFieldValue("existing_member", user.existingMember)
        // setTouched({ email: true });
        setExistingMember(true);
    }

    return (
        <>
            <style>{`
            .css-13cymwt-control {
              min-height: 45px !important; 
            }
            .css-1jqq78o-placeholder{
              font-size:14px !important;
            }
            `}
            </style>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_board_create'
                    aria-expanded='true'
                    aria-controls='kt_board_create'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Create Staff</h3>
                    </div>
                    <div className='card-title m-0'>
                        <Link to={"/staff"} className='btn btn-light'><i className="bi bi-arrow-left-short fs-1"></i>Back</Link>
                    </div>
                </div>
                <div id='kt_board_create'>
                    <form onSubmit={handleSubmit} className='form'>
                        <div className='card-body border-top p-9'>
                            <div className="row mb-6">
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>First Name</label>
                                    <input
                                        type='text'
                                        placeholder='Name'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.first_name && errors.first_name },
                                            {
                                                'is-valid': touched.first_name && !errors.first_name,
                                            }
                                        )}
                                        {...getFieldProps("first_name")}
                                    />

                                    {touched.first_name && errors.first_name && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.first_name}</div>
                                        </div>
                                    )}

                                </div>
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Last Name</label>
                                    <input
                                        type='text'
                                        placeholder='Last Name'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.last_name && errors.last_name },
                                            {
                                                'is-valid': touched.last_name && !errors.last_name,
                                            }
                                        )}
                                        {...getFieldProps("last_name")}
                                    />

                                    {touched.last_name && errors.last_name && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.last_name}</div>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className="row mb-6">
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Email</label>
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        autoComplete="off"
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.email && errors.email },
                                            {
                                                'is-valid': touched.email && !errors.email,
                                            }
                                        )}
                                        {...getFieldProps("email")}
                                        onChange={handleEmailChange}
                                    />
                                    {(!existingMember && touched.email && errors.email) || (existingMember && touched.email && errors.email) ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{errors.email}</div>
                                        </div>
                                    ) : null}
                                    {Array.isArray(emailSuggestions) && emailSuggestions.length > 0 && (
                                        <ul className="email-suggestions list-group">
                                            {emailSuggestions.map((user, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                                                    onClick={() => handleEmailSelect(user)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <span>{user.email}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                {passwordVisible && (
                                    <div className="col-lg-6 fv-row">
                                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Password</label>
                                        <input
                                            type='password'
                                            placeholder='Password'
                                            className={clsx(
                                                'form-control form-control-lg',
                                                { 'is-invalid': touched.password && errors.password },
                                                {
                                                    'is-valid': touched.password && !errors.password,
                                                }
                                            )}
                                            {...getFieldProps("password")}
                                        />
                                        {touched.password && errors.password && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{errors.password}</div>
                                            </div>
                                        )}
                                    </div>
                                )}


                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Assign Role</label>
                                    <Select
                                        name="role"
                                        className='react-select-styled react-select-solid react-select-lg  min-w-250px'
                                        classNamePrefix='react-select'
                                        options={ROLE_OPTIONS}
                                        placeholder='Select a Role...'
                                        isMulti
                                        //onChange={handleChangeStatus}
                                        onChange={(value) => {
                                            setFieldValue('role', value)
                                        }}
                                        styles={isDarkMode === 'dark' ? getSelectStyles() : ""}
                                    //value={selectedStatus}
                                    />
                                    {touched.role && errors.role && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.role}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={isSubmitting || !isValid}>
                                {!loading && 'Create Staff'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export { BoardCreate }