// @ts-nocheck
import {Column} from 'react-table'
import { BoardInfoCell } from './BoardInfoCell'
import { BoardStatusCell } from './BoardStatusCell'
import { BoardActionsCell } from './BoardActionsCell'
import { BoardSelectionCell } from './BoardSelectionCell'
import { BoardCustomHeader } from './BoardCustomHeader'
import { BoardSelectionHeader } from './BoardSelectionHeader'
import { Board } from '../../core/_models'
import { RoleCell } from './RoleCell'
import dateFormat from "dateformat";

const boardColumns: ReadonlyArray<Column<Board>> = [
  /* {
    Header: (props) => <BoardCustomHeader tableProps={props} title='No.' className='min-w-50px' />,
    id: 'no'
  }, */
  {
    Header: (props) => <BoardCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'official_name',
    Cell: ({ ...props }) => <BoardInfoCell board={props.data[props.row.index]} />,
  },
   
  /* {
    Header: (props) => (
      <BoardCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    id: 'email',
    accessor: 'email',
  }, */

  {
    Header: (props) => (
      <BoardCustomHeader tableProps={props} title='Role' className='min-w-125px' />
    ),
    id: 'role',
    Cell: ({ ...props }) => <RoleCell staff={props.data[props.row.index]} />,
  },
   
  {
    Header: (props) => (
      <BoardCustomHeader tableProps={props} title='Assigned On' className='min-w-125px' />
    ),
    id: 'updated_at',
    Cell: ({ ...props }) => (<div>{dateFormat(new Date(props.data[props.row.index].updated_at), "dS mmm, yyyy h:MM TT")}</div>)
    
  },
  {
    Header: (props) => (
      <BoardCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <BoardActionsCell staff={props.data[props.row.index]} />,
  },
]

export { boardColumns }
