import { FC, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSingleGuidelines } from "../guideline/core/_requests";

const Guidlines: FC = () => {

  const { id } = useParams<{ id: string }>();
  const [content, setContent]:any = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAcknowledgement = async (id) => {
        setLoading(true)
        try {
            const response: any = await getSingleGuidelines(id);

            if (response.status && response.status !== null) {
                setContent(response.data);
            }
        } catch (error) {
        } finally {
          setLoading(false)
        }
    };
    fetchAcknowledgement(id);
}, [id]);

  return (
    <>
      <style>{`
        .bg-checked {
          background-color: #F0F8EB; 
          border: 1px solid #82C55B;
        }`}
        </style>
      <div className='d-flex flex-column flex-root' style={{fontSize:"14px", fontFamily: 'Inter,Helvetica,"sans-serif"', backgroundColor:"#fff"}}>
        <div className="ck-content" dangerouslySetInnerHTML={{__html: content?.snippet}} />
      </div>
    </>
  )
}

export {Guidlines}
