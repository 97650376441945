import { FC } from 'react'
import { Property } from '../../../core/_models'
import { Link } from 'react-router-dom'
import { FormateAddress } from '../../../../../common/Address'

type Props = {
  property: Property
}

const PropertyInfoCell: FC<Props> = ({ property }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link to={`/property/${property.id}/edit`} className='text-gray-800 text-hover-primary mb-1'>
        {property ?
          <>
          {FormateAddress(property)}
          </>
          : ""
        }
      </Link>
    </div>
  </div>
)

export { PropertyInfoCell }
