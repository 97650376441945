// @ts-nocheck
import { Column } from 'react-table'
import { AcknowledgementInfoCell } from './AcknowledgementInfoCell'
import { AcknowledgementStatusCell } from './AcknowledgementStatusCell'
import { AcknowledgementActionsCell } from './AcknowledgementActionsCell'
// import { AcknowledgementSelectionCell } from './AcknowledgementSelectionCell'
// import { AcknowledgementSelectionHeader } from './AcknowledgementSelectionHeader'
import { AcknowledgementCustomHeader } from './AcknowledgementCustomHeader'
import { User } from '../../core/_models'
import dateFormat from "dateformat";
import { useAuth } from '../../../../auth'

const AcknowledgementColumns: ReadonlyArray<Column<Acknowledgement>> = [
  // {
  //   Header: (props) => <AcknowledgementCustomHeader tableProps={props} title='No.' className='min-w-40px' />,
  //   id: 'no'
  // },
  {
    Header: (props) => (
      <AcknowledgementCustomHeader tableProps={props} title='Title' className='min-w-100px' />
    ),
    id: 'title',
    accessor: 'title', 
    Cell: ({ row }) => ( <AcknowledgementInfoCell acknowledgement={row.original} />),
  },
  {
    Header: (props) => (
      <AcknowledgementCustomHeader tableProps={props} title='Updated at' className='min-w-125px' />
    ),
    id: 'updated_at',
    Cell: ({ ...props }) => (<div>{dateFormat(new Date(props.data[props.row.index].updated_at), "dS mmm, yyyy h:MM TT")}</div>)

  },
  {
    Header: (props) => (
      <AcknowledgementCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <AcknowledgementActionsCell id={props.data[props.row.index].id} />,
  },
]

export default AcknowledgementColumns