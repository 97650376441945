// @ts-nocheck
import {Column} from 'react-table'
import { HoaInfoCell } from './HoaInfoCell'
import { HoaStatusCell } from './HoaStatusCell'
import { HoaActionsCell } from './HoaActionsCell'
import { HoaSelectionCell } from './HoaSelectionCell'
import { HoaCustomHeader } from './HoaCustomHeader'
import { HoaSelectionHeader } from './HoaSelectionHeader'
import {User} from '../../core/_models'
import dateFormat from "dateformat";

const hoaColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <HoaCustomHeader tableProps={props} title='No.' className='min-w-30px' />,
    id: 'no'
  },
  {
    Header: (props) => <HoaCustomHeader tableProps={props} title='Official Name' className='min-w-125px' />,
    id: 'official_name',
    accessor: 'official_name',
  },
  // {
  //   Header: (props) => <HoaCustomHeader tableProps={props} title='First Name' className='min-w-125px' />,
  //   id: 'first_name',
  //   Cell: ({ ...props }) => <HoaInfoCell user={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => <HoaCustomHeader tableProps={props} title='Last Name' className='min-w-125px' />,
  //   id: 'last_name',
  //   accessor: 'last_name',
  // },
  {
    Header: (props) => (
      <HoaCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    id: 'email',
    accessor: 'email',
  },
  // {
  //   Header: (props) => (
  //     <HoaCustomHeader tableProps={props} title='Role' className='min-w-125px' />
  //   ),
  //   id: 'role',
  //   accessor: 'role',
  // },
  {
    Header: (props) => (
      <HoaCustomHeader tableProps={props} title='Created at' className='min-w-125px' />
    ),
    id: 'created_at',
    Cell: ({ ...props}) =>(<div>{dateFormat(new Date(props.data[props.row.index].created_at), "dS mmm, yyyy h:MM TT")}</div>)
    
  },
  {
    Header: (props) => (
      <HoaCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <HoaActionsCell id={props.data[props.row.index].id} />,
  },
]

export { hoaColumns }
