import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useAuth } from "../../../../app/modules/auth";
import { useEffect, useState } from 'react';
import { hoaUpdate } from '.';
import { useNavigate } from 'react-router-dom';

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const { config } = useLayout()
  const { currentUser, setCurrentUser, saveAuth }: any = useAuth();
  const [selectedHOA, setSelectedHOA] = useState<number>(currentUser?.selected_hoa_id);
  useEffect(() => {
    if (currentUser) {
      setSelectedHOA(currentUser.selected_hoa_id);
    }
  }, [currentUser]);

  const handleHOAChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newHOA = parseInt(e.target.value);
    setSelectedHOA(newHOA)
    const { data: data }: any = await hoaUpdate(newHOA);
    setCurrentUser((prevValues) => ({
      ...prevValues,
      selected_hoa_id: data.selected_hoa_id,
      api_token: data.api_token
    }));
    saveAuth({
      ...currentUser,
      selected_hoa_id: data.selected_hoa_id,
      api_token: data.api_token
    });
    if (currentUser.assignRole.includes('review_board_member')) {
      window.location.href = '/request';
    } else {
      window.location.href = '/dashboard';
    }
  };
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      {(currentUser.role.includes('hoa') || currentUser.role.includes('review_board_member')) &&
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            className={clsx('position-relative', btnClass)}
          >
            <KTIcon iconName='message-text-2' className={btnIconClass} />
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
          </div>
          <HeaderNotificationsMenu />
        </div>
      }
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>  */}
      <div className={clsx('app-navbar-item', itemClass)}>
        {currentUser.AssignedRoles.length >= 2 ?
          <select
            className="form-select form-select-lg"
            onChange={handleHOAChange}
            data-control="select2"
            style={{ width: '300px' }}
            value={selectedHOA}

          >
            {currentUser?.AssignedRoles.map((role: any, index: number) => (
              <option key={index} value={role.hoa_id}>
                {role.official_name}
              </option>
            ))}
          </select>
          : ""}


        {/* <span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-9-xb85" style={{ width: '100%' }}>
          <span className="selection">
            <span className="select2-selection select2-selection--single form-select form-select-lg" role="combobox" aria-haspopup="true" aria-expanded="false" aria-disabled="false" aria-labelledby="select2-school_id-container" aria-controls="select2-school_id-container">
              <span className="select2-selection__rendered" id="select2-school_id-container" role="textbox" aria-readonly="true" title="Swaminarayan school una">Swaminarayan school una</span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
            </span>
          </span>
          <span className="dropdown-wrapper" aria-hidden="true"></span>
        </span> */}
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/300-3.jpg')} alt='' /> */}
          <div className="symbol symbol-40px "><span className="symbol-label  bg-light-danger text-danger fs-6 fw-bolder ">
            {currentUser.role == 'hoa' ? currentUser.official_name
              .match(/(^\S\S?|\b\S)?/g)
              .join("")
              .match(/(^\S|\S$)?/g)
              .join("")
              .toUpperCase()
              : `${currentUser.first_name[0].toUpperCase()}${currentUser.last_name[0].toUpperCase()}`}</span></div>
        </div>
        <HeaderUserMenu />
      </div>


    </div>
  )
}

export { Navbar }
