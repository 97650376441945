import clsx from 'clsx'
import { useQueryResponseLoading, useQueryResponsePagination } from '../core/QueryResponseProvider'
import { useQueryRequest } from '../core/QueryRequestProvider'
import generatePaginationLinks from '../../../../common/PaginationLink'

const BoardListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { updateState } = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }
    updateState({ page, items_per_page: pagination.items_per_page || 10 })
  }
  
  // Showing the current page's item range from total records.
  const { items_per_page = 10, page = 1, total = 0 } = pagination;
  const startItem = total === 0 ? 0 : (page - 1) * items_per_page + 1;
  const endItem = total === 0 ? 0 : Math.min(page * items_per_page, total);

  // Pagination links
  const totalPages = Math.ceil(total / items_per_page);
  const paginationLinks: any[] = generatePaginationLinks(page, totalPages);

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start fw-bold fs-6'>
        {`Showing ${startItem} to ${endItem} of ${total} entries`}
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_boards_paginate'>
          <ul className='pagination'>
            {paginationLinks.map((link) => (
              <li
                key={link.key}
                className={clsx('page-item', {
                  active: page === link.page,
                  disabled: isLoading || link.disabled,
                })}
              >
                <a
                  className='page-link'
                  onClick={() => updatePage(link.page)}
                  style={{ cursor: link.disabled ? 'default' : 'pointer' }}
                >
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export { BoardListPagination }