import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup'
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getModifyAcknowledgement, modifyAcknowledgement } from "./core/_requests";
import { ModificationAcknowledge, AckInitValues as initialValues } from "./core/_models";
import { useThemeMode } from "../../../_metronic/partials";
import InputSkeleton from "../../common/Skeleton/InputSkeleton";
const acknowledgementModifySchema = Yup.object().shape({
    title: Yup.string().required('This field is required.')
})
const ModificationRequestAcknowledge: FC = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate();
    const { mode } = useThemeMode();

    const { handleSubmit, touched, errors, isSubmitting, isValid, values, setFieldValue, setFieldTouched } = useFormik<ModificationAcknowledge>({
        initialValues: initialValues,
        validationSchema: acknowledgementModifySchema,
        onSubmit: async (values: ModificationAcknowledge, { setStatus, setSubmitting }: FormikHelpers<ModificationAcknowledge>) => {
            setLoading(true)
            setError(null)
            try {
                values.title = `<div style="font-family: ${process.env.REACT_APP_CONTENT_FONT_FAMILY};font-size: ${process.env.REACT_APP_CONTENT_FONT_SIZE};">${values.title}</div>`;
                const data: any = await modifyAcknowledgement(values);
                if (data.status) {
                    setLoading(false)
                    setSubmitting(false);
                    toast.success('Acknowledgement Modified successfully.')
                    navigate('/modification-acknowledge'); 
                }
            } catch (error) {
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    useEffect(() => {
        const fetchAcknowledgement = async () => {
            setLoading(true)
            try {
                const data = await getModifyAcknowledgement();
                if (data?.status) {
                    setFieldValue('title', data.data.title || "");
                } else {
                    setFieldValue('title',"");
                }
                setLoading(false)
            } catch (error) {
                toast.error('Error fetching acknowledgment data.');
                setLoading(false)
            }
        };
        fetchAcknowledgement();

    }, [setFieldValue]);
    return (
        <>
            {mode === 'dark' && (
                <style>{`
                    .ck.ck-editor__main>.ck-editor__editable{
                        background-color: #1C1C1C !important; 
                    }
                    `}
                </style>)
            }
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_acknowledgement_modify'
                    aria-expanded='true'
                    aria-controls='kt_acknowledgement_modify'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Modification Request Acknowledgement</h3>
                    </div>
                </div>
                <div id='kt_acknowledgement_modify'>
                    <form onSubmit={handleSubmit} className='form'>
                        <div className='card-body border-top p-9'>
                            {loading ? (<InputSkeleton rows={1} columns={1} colsWidth={12} height={200} />) :
                                <div className="row mb-6">
                                    <div className="col-lg-12 fv-row">
                                        {/* <label className="required fw-bold fs-6 mb-2">Title</label> */}
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={values.title || ""}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setFieldValue('title', data);
                                            }}
                                            onBlur={() => setFieldTouched('title')}
                                        />
                                        {touched.title && errors.title && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{errors.title}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={isSubmitting || !isValid}>
                                {!loading && 'Modify Acknowledgement'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export { ModificationRequestAcknowledge }

