/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout }: any = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <div className="symbol symbol-40px">
              <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                {currentUser.role === 'hoa'
                  ? currentUser.official_name
                    .match(/(^\S\S?|\b\S)?/g)          
                    .join("")                          
                    .match(/(^\S|\S$)?/g)             
                    .join("")                         
                    .toUpperCase()                    
                  : `${currentUser.first_name[0].toUpperCase()}${currentUser.last_name[0].toUpperCase()}`
                }
              </span>
            </div>

          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser.role === 'hoa' ? currentUser?.official_name.charAt(0).toUpperCase() + currentUser?.official_name.slice(1).toLowerCase() : `${currentUser?.first_name.charAt(0).toUpperCase() + currentUser?.first_name.slice(1).toLowerCase()} ${currentUser?.last_name.charAt(0).toUpperCase() + currentUser?.last_name.slice(1).toLowerCase() }`}
            </div>
            {/*  <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'> {currentUser?.role}</span> */}
            <a href='#' className='fw-bold text-muted text-break text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
            <div>
              {/* <span className="badge badge-light-success fw-bolder fs-8 py-1 mt-2">{currentUser?.role.charAt(0).toUpperCase() + currentUser?.role.slice(1)}</span> */}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 my-1'>
        <Link to='/account/profile' className='menu-link px-5'>
          My Profile
        </Link>
      </div>
      <div className='menu-item px-5 my-1'>
        <Link to='/account/password' className='menu-link px-5'>
          Change Password
        </Link>
      </div>
      {currentUser.role.includes("administrator") &&
        <div className='menu-item px-5 my-1'>
          <Link to='/account/settings' className='menu-link px-5'>
            Settings
          </Link>
        </div>
      }
     

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
