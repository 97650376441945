import { Response } from "../../../../_metronic/helpers";

// response
export interface IResponse {
    status: boolean;
    message: string;
    data: any;
    error: any;
}
export type Acknowledgement = {
    id?: number
    title?: string
    member?:
    {
        official_name: string
    }
}

export type AcknowledgementQueryResponse = Response<Array<Acknowledgement>>

// create Acknowledgement
export type IAcknowledgementCreate = {
    id?: number
    title: string
    member:
    {
        official_name: string
    }
}
export const AckInitValues: IAcknowledgementCreate = {
    title: "",
    member: {
        official_name: ""
    }

}

// update Acknowledgement
export type IAcknowledgementUpdate = {
    id?: number
    title: string
}
export const AckUpdateInitValues: IAcknowledgementUpdate = {
    title: "",
}