import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../auth'
import { ModificationRequestAcknowledge } from './ModificationRequestAcknowledge'

const memberCreateBreadcrumbs: Array<PageLink> = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ModificationAcknowledgePage = () => {
    const { currentUser }: any = useAuth()
    return (
        <Routes>
            <Route element={<Outlet />}>
                <>
                    <Route
                        path='/'
                        element={
                            <>
                                <PageTitle breadcrumbs={memberCreateBreadcrumbs}>Modification Request Acknowledgement</PageTitle>
                                <ModificationRequestAcknowledge />
                            </>
                        }
                    />

                </>
                : <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

export default ModificationAcknowledgePage
