
import axios, { AxiosResponse } from "axios";
import { ModificationAcknowledge, IResponse, ModificationRequestAcknowledgeQueryResponse } from "./_models";
import { ID } from "../../../../_metronic/helpers";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL
const ACKNOWLEDGEMENT_MODIFY_URL = `${API_URL}/admin/modification-request-acknowledgements`

async function modifyAcknowledgement(payload: ModificationAcknowledge): Promise<IResponse | null> {
    try {
        const response: AxiosResponse<IResponse> = await axios.post(ACKNOWLEDGEMENT_MODIFY_URL, payload);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to Modify Acknowledgement. Please try again.");
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function getModifyAcknowledgement(): Promise<any> {
    try {
        const response: AxiosResponse<any> = await axios.get(ACKNOWLEDGEMENT_MODIFY_URL);
        
        if (response && response.data.status == true) {
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Access denied or Acknowledgement not found');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
 
export { modifyAcknowledgement, getModifyAcknowledgement }
