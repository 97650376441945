/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Acknowledgement } from '../../../core/_models'
import { Link } from 'react-router-dom'

type Props = {
  acknowledgement: Acknowledgement
}
const AcknowledgementInfoCell: FC<Props> = ({ acknowledgement }) => {
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='d-flex flex-column'>
        <Link
          to={`/acknowledgement/${acknowledgement.id}/edit`}
          className='text-gray-800 text-hover-primary mb-1'
        >
          <span dangerouslySetInnerHTML={{ __html: acknowledgement.title || '' }} />
        </Link>
      </div>
    </div>
  )
}

export { AcknowledgementInfoCell }
