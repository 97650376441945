import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import { useLayout, usePageData } from '../../../core';
import clsx from 'clsx';

export function MenuInner() {
  const {pageTitle} = usePageData()
  const {config,} = useLayout();
  const appPageTitleDirection = config.app?.pageTitle?.direction
  
  return (
    
    <h1
      className={clsx('page-heading d-flex text-dark fw-bold fs-3 my-0', {
        'flex-column justify-content-center': appPageTitleDirection,
        'align-items-center': !appPageTitleDirection,
      })}
    >
      {pageTitle}
    </h1>
     
  )
}
