import { ID, Response } from '../../../../_metronic/helpers'
export type Board = {
    id?: ID
    created_at: string
    role: string[]
    member: any
    email?: any

}

export type BoardQueryResponse = Response<Array<Board>>

export const initialBoard: Board = {
    id:'',
    created_at:'',
    email:'',
    member:{},
    role: []

}


// response
export interface IBoardResponse {
    status: boolean;
    message: string;
    data: any;
    error: any;
}

export type IBoardCreate = {
    first_name: string
    last_name:string
    email: string
    password: string
    //business_address?: string
    role: string[]
    existing_member:boolean
}
export const boardInitValues: IBoardCreate = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    //business_address: "",
    role: [],
    existing_member:false
}
export type IBoardUpdate = {
    id: string
    first_name: string
    last_name: string
    email: string
    password: string
    role: string[]
}
export type IBoardRoleUpdate = {
    member_id: string
    role: string[]
}
export const boardRoleUpdateInitValues: IBoardRoleUpdate = {
    member_id: '',
    role: [],
}
export const boardUpdateInitValues: IBoardUpdate = {
    id: '',
    first_name: '',
    last_name:'',
    email: '',
    password: '',
    role: [],
}
