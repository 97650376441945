import { Response } from "../../../../_metronic/helpers";

// response
export interface IResponse {
    status: boolean;
    message: string;
    data: any;
    error: any;
    title: any;
}
export type ModifyAcknowledgement = {
    id?: number
    title?: string
    
}

export type ModificationRequestAcknowledgeQueryResponse = Response<Array<ModifyAcknowledgement>>

export type ModificationAcknowledge= {
    id?: number
    title: string
   
}
export const AckInitValues: ModificationAcknowledge = {
    title: "",
} 