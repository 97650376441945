import axios, { AxiosResponse } from "axios";
import { IPropertyCreate, IPropertyResponse, IPropertyUpdate } from "./_models";
import { ID } from "../../../../_metronic/helpers";
import { Property, PropertyQueryResponse} from './_models'
import { toast } from "react-toastify";
import { IImpactedPropertyUpdate } from "./_models";

const API_URL = process.env.REACT_APP_API_URL
const PROPERTY_URL = `${API_URL}/admin/properties`
const REQUEST_URL = `${API_URL}/admin/request`

const getProperties = (query: string): Promise<PropertyQueryResponse> => {
    return axios
      .get(`${PROPERTY_URL}?${query}`)
        .then((d: AxiosResponse<PropertyQueryResponse>) => d.data)
}

// Create Member
async function createProperty(payload: IPropertyCreate): Promise<IPropertyResponse | null> {
    try {
        const response: AxiosResponse<IPropertyResponse> = await axios.post(PROPERTY_URL, payload);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('API Error:', error.response?.data || error.message);
        } else {
            console.error('Unexpected Error:', error);
        }
        return null;
    }
}

// Get Member
async function getProperty(id: string): Promise<IPropertyResponse | null> {
    try {
        const response: AxiosResponse<IPropertyResponse> = await axios.get(`${PROPERTY_URL}/${id}`);
        
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('API Error:', error.response?.data || error.message);
        } else {
            console.error('Unexpected Error:', error);
        }
        return null;
    }
}

// Update Property
async function updateProperty(payload: IPropertyUpdate): Promise<IPropertyResponse | null> {
    try {
        const response: AxiosResponse<IPropertyResponse> = await axios.patch(`${PROPERTY_URL}/${payload.id}`, payload);        
        return response.data;

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('API Error:', error.response?.data || error.message);
        } else {
            console.error('Unexpected Error:', error);
        }
        return null;
    }
}

// Update Impacted Property
async function updateImpactedProperty(payload: IImpactedPropertyUpdate): Promise<IPropertyResponse | null> {
    try {
        const response: AxiosResponse<IPropertyResponse> = await axios.post(`${PROPERTY_URL}/update-impacted`, payload);        
        return response.data;

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('API Error:', error.response?.data || error.message);
        } else {
            console.error('Unexpected Error:', error);
        }
        return null;
    }
}

// Delete Impacted Property
async function deleteImpactedProperty(id): Promise<IPropertyResponse | null> {
    try {
        const response: AxiosResponse<IPropertyResponse> = await axios.delete(`${PROPERTY_URL}/delete-impacted/${id}`);        
        return response.data;

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('API Error:', error.response?.data || error.message);
        } else {
            console.error('Unexpected Error:', error);
        }
        return null;
    }
}



// Delete Property
async function deleteProperty(id: ID): Promise<IPropertyResponse | null> {
    try {
        const response: AxiosResponse<IPropertyResponse> = await axios.delete(`${PROPERTY_URL}/${id}`);
        return response.data;
    } catch (error) {   
        if (axios.isAxiosError(error)) {
            console.error('API Error:', error.response?.data || error.message);
        } else {
            console.error('Unexpected Error:', error);
        }
        return null;
    }
}
async function searchMember(keyword: any): Promise<IPropertyResponse | null> {
    try {
        const response: AxiosResponse<IPropertyResponse> = await axios.post(`${REQUEST_URL}/search-member`, { keyword });
        if (response) {
            return response.data;
        } else {
            toast.error('Failed to search Member: ' + response);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
export { getProperties, createProperty, getProperty, updateProperty, deleteProperty, searchMember, updateImpactedProperty, deleteImpactedProperty};

// if api get metadata then use this  type

// const createMember = (member: IMemberCreate): Promise<IMemberCreate | undefined> => {
//     return axios
//         .post(PROPERTY_URL, member)
//         .then((response: AxiosResponse<Response<IMemberCreate>>) => response.data)
//         .then((response: Response<IMemberCreate>) => response.data)
// }