import { FC } from 'react';
import { Board } from '../../../core/_models';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../auth';

type Props = {
  board: Board;
};

const BoardInfoCell: FC<Props> = ({ board }) => {

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          <div className='symbol-label fs-3 bg-light-danger text-danger'>
            {`${board.member.first_name?.substring(0, 1).toUpperCase()}${board.member.last_name?.substring(0, 1).toUpperCase()}`}
          </div>
        </a>
      </div>

      <div className='d-flex flex-column'>
        {board.role.includes('hoa') ? (
          <>
            <a className='text-gray-800 text-hover-primary mb-1'>
              {`${board?.member.first_name.charAt(0).toUpperCase() + board.member.first_name.slice(1).toLowerCase()} ${board.member.last_name.charAt(0).toUpperCase() + board.member.last_name.slice(1).toLowerCase()}`}
            </a>
            <span className='text-muted'>{board.member.email}</span>
          </>
        ) : (
          <>
            <Link
              to={`/members/${board.member.id}/edit`}
              className='text-gray-800 text-hover-primary mb-1'
            >
              {`${board.member.first_name} ${board.member.last_name}`}
            </Link>
            <span className='text-muted'>{board.member.email}</span>
          </>
        )}
      </div>
    </div>
  );
};

export { BoardInfoCell };
