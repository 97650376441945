/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { Link } from 'react-router-dom'
import { deleteAcknowledgement } from '../../../core/_requests'

import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
type Props = {
  id: ID
}

const AcknowledgementActionsCell: FC<Props> = ({ id }) => {

  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteItem = useMutation(async (id: ID) => {
    // try {
    await deleteAcknowledgement(id);
    toast.success("Acknowledgement deleted successfully. The acknowledgement will unassign all properties assigned to them, and these properties can then be reassigned to a new member.");

      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
    // } 
    // catch (error) {
    //   toast.error("Failed to delete the member. Please try again.");
    // }
  });
  const handleAcknowledgement = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone! Deleting the acknowledgement will unassign all properties assigned to them, and these properties can then be reassigned to a new member.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3e97ff',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      cancelButtonColor: '#3e97ff',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem.mutate(id)
      }
    })
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <Link to={`/acknowledgement/${id}/edit`} className='menu-link px-3'>
            Edit
          </Link>
        </div>

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            onClick={handleAcknowledgement}
          >
            Delete
          </a>
        </div>
      </div>
    </>
  )
}

export { AcknowledgementActionsCell }
