import { Link, useNavigate, useParams } from "react-router-dom"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { IPropertyUpdate, propertyUpdateInitValues as initialValues } from "./core/_models"
import { useEffect, useState } from "react"
import { getProperty, searchMember, updateProperty } from "./core/_requests"
import clsx from "clsx"
import { toast } from "react-toastify"
import InputSkeleton from "../../common/Skeleton/InputSkeleton"
import Select from 'react-select';
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers"
import { AddImpactedModal } from "./AddImpactedModal"
import { deleteImpactedProperty } from "./core/_requests"
import Swal from "sweetalert2"
import { FormateAddress } from "../../common/Address"

interface OptionType {
    value: string;
    label: string;
}
const propertyEditSchema = Yup.object().shape({
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip: Yup.string().required('Zip is required'),
    plat: Yup.string().nullable(),
    /* member: Yup.object()
    .nullable()
    .test('isValidMember', 'A member must be selected', (value:any) => value && value.value), */
})
const PropertyEdit: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [data, setData] = useState<IPropertyUpdate>(initialValues)
    const [options, setOptions] = useState<OptionType[]>([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [sections, setSections] = useState([{ id: Date.now() }]);
    const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
    const [enableImpactedProperty, setEnableImpactedProperty] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [impactedProperty, setImpactedProperty] = useState([]);

    const formik: any = useFormik({
        initialValues,
        validationSchema: propertyEditSchema,
        onSubmit: async (values: IPropertyUpdate) => {

            setLoading(true);
            try {
                /* const selectedMember:any = values?.member;
                values.member_id = selectedMember.value??''; */
                const response: any = await updateProperty(values);
                if (response.status = true) {
                    toast.success('Property updated successfully.');
                    navigate('/property');
                }
            } catch (error) {
                console.error('Failed to update property:', error);
                setError('Failed to update property. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        const fetchProperty = async () => {
            if (!id) {
                setError("Invalid Property ID.");
                return;
            }
            setLoading(true);
            try {
                const response = await getProperty(id);

                if (response) {
                    const propertyData = {
                        ...initialValues,
                        ...response.data,
                    };

                    /* let opt:any = propertyData.member? { value: propertyData.member.id, label: propertyData.member.first_name+' '+propertyData.member.last_name}:{ value: "", label: "" };
                    setOptions([opt]); */
                    const fetchedData: any = {
                        id: propertyData.id,
                        address: propertyData.address || '',
                        city: propertyData.city || '',
                        state: propertyData.state || '',
                        zip: propertyData.zip || '',
                        plat: propertyData.plat || '',
                        /* member: opt */
                    };
                    setData(fetchedData)
                    //setSelectedOptions(opt);
                    formik.setValues(fetchedData);
                    setImpactedProperty(propertyData.impactedProperty);

                } else {
                    throw new Error("Invalid response data");
                }
            } catch (error) {
                console.error("Failed to fetch member:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProperty();
    }, [id, refresh]);
    const handleMemberChange = (selectedOption: OptionType | null) => {
        formik.setFieldValue('member_id', selectedOption ? selectedOption.value : '');

    };
    useEffect(() => {
        const fetchMember = async () => {
            try {
                if (searchKeyword) {
                    const response = await searchMember(searchKeyword);
                    if (response) {
                        setOptions(response.data.map((item) => ({
                            value: item.id,
                            label: item.first_name + ' ' + item.last_name,
                        })))
                    }
                }
            } catch (error) {

            }
        }
        fetchMember()
    }, [searchKeyword]);

    const handleAddMore = () => {
        setSections([...sections, { id: Date.now() }]);
    };
    const handleDeleteImpact = async (id) => {


        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: "#3085d6",
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    const response = await deleteImpactedProperty(id);
                    if (response) {
                        toast.success('Property Delete successfully.');
                        setRefresh((prev) => !prev);
                    }
                } catch (error) {
                    toast.error('Property Delete error: ' + error);
                }
            }
        });
    }
    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    // 
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Edit Property</h3>
                    </div>
                    <div className='card-title m-0'>
                        <Link to={"/property"} className='btn btn-light'><i className="bi bi-arrow-left-short fs-1"></i>Back</Link>
                    </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                    {/* {loading ? (<InputSkeleton rows={5} columns={2} />) : ( */}
                    <form onSubmit={formik.handleSubmit} className='form'>
                        {loading ? (<InputSkeleton rows={5} columns={2} />) : (
                            <div className='card-body border-top p-9'>
                                <div className="row mb-6">
                                    {/* <div className="col-lg-6 fv-row">
                                        <label className="col-form-label required fw-semibold fs-6">Select Member</label>
                                        <Select
                                            name="member"
                                            options={options}
                                            onChange={(value:any) => {
                                                formik.setFieldValue('member', value);
                                                setSelectedOptions(value);
                                            }}
                                            onInputChange={(keyword) => setSearchKeyword(keyword)}
                                            isSearchable
                                            placeholder="Search Members..."
                                            className=""
                                            value={selectedOptions}
                                        />
                                        {formik.touched.member && formik.errors.member && (
                                            <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.member}</div>
                                            </div>
                                        )}
                                    </div> */}
                                    <div className="col-lg-12 fv-row">
                                        <label className="col-form-label required fw-semibold fs-6">Address</label>
                                        <textarea
                                            placeholder='Address'
                                            rows={5}
                                            cols={10}
                                            className={clsx(
                                                'form-control form-control-lg mb-3 mb-lg-0',
                                                { 'is-invalid': formik.touched.address && formik.errors.address },
                                                { 'is-valid': formik.touched.address && !formik.errors.address }
                                            )}
                                            {...formik.getFieldProps('address')}
                                        ></textarea>
                                        {formik.touched.address && formik.errors.address && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.address}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row mb-6">
                                    <div className="col-lg-6 fv-row">
                                        <label className="col-form-label required fw-semibold fs-6">City</label>
                                        <input
                                            type='text'
                                            className={clsx(
                                                'form-control form-control-lg',
                                                { 'is-invalid': formik.touched.city && formik.errors.city },
                                                {
                                                    'is-valid': formik.touched.city && !formik.errors.city,
                                                }
                                            )}
                                            placeholder='City'
                                            {...formik.getFieldProps('city')}
                                        />
                                        {formik.touched.city && formik.errors.city && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.city}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 fv-row">
                                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>State</label>
                                        <input
                                            type='text'
                                            placeholder='State'
                                            className={clsx(
                                                'form-control form-control-lg',
                                                { 'is-invalid': formik.touched.state && formik.errors.state },
                                                {
                                                    'is-valid': formik.touched.state && !formik.errors.state,
                                                }
                                            )}
                                            {...formik.getFieldProps('state')}
                                        />
                                        {formik.touched.state && formik.errors.state && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.state}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row mb-6">
                                    <div className="col-lg-6 fv-row">
                                        <label className="col-form-label required fw-semibold fs-6">Zip</label>
                                        <input
                                            type='text'
                                            className={clsx(
                                                'form-control form-control-lg',
                                                { 'is-invalid': formik.touched.zip && formik.errors.zip },
                                                {
                                                    'is-valid': formik.touched.zip && !formik.errors.zip,
                                                }
                                            )}
                                            placeholder='Zip'
                                            {...formik.getFieldProps('zip')}
                                        />
                                        {formik.touched.zip && formik.errors.zip && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.zip}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 fv-row">
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Plat</label>
                                        <input
                                            type='text'
                                            placeholder='Plat'
                                            className={clsx(
                                                'form-control form-control-lg',
                                                // { 'is-invalid': formik.touched.plat && formik.errors.plat },
                                                // {
                                                //     'is-valid': formik.touched.plat && !formik.errors.plat,
                                                // }
                                            )}
                                            {...formik.getFieldProps('plat')}
                                        />
                                        {/* {formik.touched.plat && formik.errors.plat && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.plat}</div>
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        )
                        }
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || !formik.isValid}>
                                {!loading && 'Update Property'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                    {/* )
            } */}
                </div>
            </div>
            <div className={`card`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Impacted Property</span>
                    </h3>
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to add a user'
                    >
                        <a
                            href='#'
                            className='btn btn-sm btn-light-primary'
                            onClick={() => setEnableImpactedProperty(true)}
                        >
                            <KTIcon iconName='plus' className='fs-3' />
                            Add New
                        </a>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/*  <th className='min-w-150px'>Impacted Member</th> */}
                                    <th className='min-w-140px'>Impacted Property</th>
                                    <th className='min-w-100px text-end'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {impactedProperty.length > 0 ? (
                                    impactedProperty.map((row: any, i) => {

                                        return <tr key={i}>
                                            {/* <td>
                                            <div className='d-flex align-items-center'>
                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                    <a href="#"><div className="symbol-label fs-3 bg-light-danger text-danger">{row.first_name?.substring(0, 1).toUpperCase()}</div></a>
                                                </div>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>{row.first_name+' '+row.last_name}</a>
                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>{row.email}</span>
                                                </div>
                                            </div>
                                        </td> */}
                                            <td>
                                                <a className="text-dark fw-bold text-hover-primary d-block fs-6">
                                                    {FormateAddress(row)}
                                                </a>
                                            </td>

                                            <td>
                                                <div className='d-flex justify-content-end flex-shrink-0'>
                                                    <a
                                                        onClick={() => handleDeleteImpact(row.id)}
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                    >
                                                        <KTIcon iconName='trash' className='fs-3' />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                ) : (<></>)}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {enableImpactedProperty && <AddImpactedModal enableImpactedProperty={enableImpactedProperty} setEnableImpactedProperty={setEnableImpactedProperty} id={id} setRefresh={setRefresh} />}

        </>
    )
}

export { PropertyEdit }