import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { AcknowledgementEdit } from "./AcknowledgementEdit";
import { AcknowledgementCreate } from "./AcknowledgementCreate";
import { AcknowledgementListWrapper } from "./acknowledgement-list/AcknowledgementList";

const acknowledgementBreadcrumbs: Array<PageLink> = [
    { title: 'Dashboard', path: '/dashboard', isSeparator: false, isActive: false },
    { title: '', path: '', isSeparator: true, isActive: false },
]
const acknowledgementCreateBreadcrumbs: Array<PageLink> = [
    { title: 'List Acknowledgement', path: '/acknowledgement', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const acknowledgementEditBreadcrumbs: Array<PageLink> = [
    { title: 'List Acknowledgement', path: '/acknowledgement', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const AcknowledgementPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={acknowledgementBreadcrumbs}>List Acknowledgement</PageTitle>
                            <AcknowledgementListWrapper />
                        </>
                    }
                />
                <Route
                    path='create'
                    element={
                        <>
                            <PageTitle breadcrumbs={acknowledgementCreateBreadcrumbs}>Create New Acknowledgement</PageTitle>
                            <AcknowledgementCreate />
                        </>
                    }
                />
                <Route
                    path=':id/edit'
                    element={
                        <>
                            <PageTitle breadcrumbs={acknowledgementEditBreadcrumbs}>Edit Acknowledgement</PageTitle>
                            <AcknowledgementEdit />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}
export { AcknowledgementPage }
