import { Link } from 'react-router-dom'
import {useEffect, useState} from 'react'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'
import Select from 'react-select';
import { getSelectStyles, ROLE_OPTIONS } from '../../../../../global.d';
import { RoleEditModal } from './RoleEditModal';

type DataItem = {
  value: string;
  label: string;
};

const BoardListHeader = () => {
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState(null);
  const [itemIdForUpdate, setItemIdForUpdate] = useState(false);
  const isDarkMode = localStorage.getItem('kt_theme_mode_menu')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  useEffect(
    () => {
      if (selectedRole !== undefined || (debouncedSearchTerm !== undefined && searchTerm !== undefined)) {
        const roleArrData:DataItem[] = selectedRole??[];
        const roleArr = roleArrData.map(item => item.value);
        updateState({search: debouncedSearchTerm,  filter: { 'role': roleArr}, ...initialQueryState})
      }
    },
    [debouncedSearchTerm, selectedRole] 
  )

  const handleChangeRole = (values) => {
    setSelectedRole(values);
  }

  const handResetButton = () => {
    setSearchTerm('');
    setSelectedRole(null);
  }

  return (
    <>
      <style>{`
        .css-13cymwt-control {
          min-height: 45px !important; 
        }
        .css-1jqq78o-placeholder{
          font-size:14px !important;
        }
        `}
      </style>
      <div className='card-header border-0 pt-6'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1 me-3'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-board-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search Staff'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className='d-flex justify-content-end me-3' data-kt-user-table-toolbar='base'>
            <Select 
                className='react-select-styled react-select-solid react-select-lg  min-w-250px'
                classNamePrefix='react-select' 
                options={ROLE_OPTIONS} 
                placeholder='Select a Role...' 
                isMulti 
                onChange={handleChangeRole}
                value={selectedRole}
                styles={isDarkMode === 'dark' ? getSelectStyles() : ""}
            />
          </div>
          <a href='#' className='btn btn-primary' onClick={handResetButton}>Reset</a>
        </div>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end' data-kt-board-table-toolbar='base'>
            <Link to={"/staff/create"} className='btn btn-primary me-3'> 
              <KTIcon iconName='plus' className='fs-2' />
              Add New Staff
            </Link>
            <button type='button' className='btn btn-primary' onClick={() => setItemIdForUpdate(true)}>
              <KTIcon iconName='plus' className='fs-2' />
              Assign Member Role
            </button>
          </div>
        </div>
      </div>
      {itemIdForUpdate && <RoleEditModal itemIdForUpdate={itemIdForUpdate} setItemIdForUpdate={setItemIdForUpdate}/>}
    </>
  )
}

export { BoardListHeader }
