import { FormikHelpers, useFormik } from "formik";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup'
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { createAcknowledgement } from "./core/_requests";
import { IAcknowledgementCreate, AckInitValues as initialValues } from "./core/_models";
import { useThemeMode } from "../../../_metronic/partials";
const acknowledgementCreateSchema = Yup.object().shape({
    title: Yup.string().required('This field is required')
})
const AcknowledgementCreate: FC = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate();
    const { mode } = useThemeMode();

    const { handleSubmit, touched, errors, isSubmitting, isValid, values, setFieldValue, setFieldTouched } = useFormik<IAcknowledgementCreate>({
        initialValues: initialValues,
        validationSchema: acknowledgementCreateSchema,
        onSubmit: async (values: IAcknowledgementCreate, { setStatus, setSubmitting }: FormikHelpers<IAcknowledgementCreate>) => {
            setLoading(true)
            setError(null)
            try {
                values.title = `<div style="font-family: ${process.env.REACT_APP_CONTENT_FONT_FAMILY};font-size: ${process.env.REACT_APP_CONTENT_FONT_SIZE};">${values.title}</div>`;
                const data: any = await createAcknowledgement(values);
                if (data.status) {
                    setLoading(false)
                    setSubmitting(false);
                    toast.success('Acknowledgement created successfully.')
                    navigate('/acknowledgement');
                } 
                // else {
                //     toast.error(data.error);
                //     setSubmitting(false)
                //     setLoading(false)
                // }
            } catch (error) {
                // toast.error('Failed to create acknowledgement. Please try again.')
                // setStatus('Failed to create acknowledgement')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    return (
        <>
            {mode === 'dark' && (
                <style>{`
                    .ck.ck-editor__main>.ck-editor__editable{
                        background-color: #1C1C1C !important; 
                    }
                    `}
                </style>)
            }
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_acknowledgement_create'
                    aria-expanded='true'
                    aria-controls='kt_acknowledgement_create'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Create Acknowledgement</h3>
                    </div>
                    <div className='card-title m-0'>
                        <Link to={"/acknowledgement"} className='btn btn-light'><i className="bi bi-arrow-left-short fs-1"></i>Back</Link>
                    </div>
                </div>
                <div id='kt_acknowledgement_create'>
                    <form onSubmit={handleSubmit} className='form'>
                        <div className='card-body border-top p-9'>
                            <div className="row mb-6">
                                <div className="col-lg-12 fv-row">
                                    {/* <label className="required fw-bold fs-6 mb-2">Title</label> */}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={values.title}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setFieldValue('title', data);
                                        }}
                                        onBlur={() => setFieldTouched('title')}
                                    />
                                    {touched.title && errors.title && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.title}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={isSubmitting || !isValid}>
                                {!loading && 'Create Acknowledgement'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export { AcknowledgementCreate }

