// declare module '@yaireo/tagify';
// declare module '@yaireo/tagify/dist/react.tagify';

export const REQUEST_COLOR_DATA = {
    "submitted": {
        color: "grey",
        title: "Submitted"
    },
    "in_review": {
        color: "warning",
        title: "Under Review"
    },
    "hoa_reviewed": {
        color: "primary",
        title: "Ready for Board Review"
    },
    "needs_discussion": {
        color: "primary",
        title: "Pending Discussion"
    },
    "approved": {
        color: "success",
        title: "Approved"
    },
    "cancelled": {
        color: "danger",
        title: "Cancelled"
    },
    "denied": {
        color: "danger",
        title: "Denied"
    },
};

export const HOA_ROLES_DATA = {
    "hoa": {
        color: "danger",
        title: "Super Admin"
    },
    "admin_staff": {
        color: "success",
        title: "Admin/Staff"
    },
    "review_board_member": {
        color: "primary",
        title: "Review Board Member"
    },
    "board_of_directors": {
        color: "info",
        title: "Board of Directors"
    }
};

export const ROLE_OPTIONS: any = [
    { value: 'admin_staff', label: 'Admin/Staff' },
    { value: 'review_board_member', label: 'Review Board Member' },
    { value: 'board_of_directors', label: 'Board of Directors' }
];
export const ROLES = {
    member: {
        color: "success",
        title: "Member",
    },
    administrator: {
        color: "primary",
        title: "Super Admin",
    },
    hoa: {
        color: "danger",
        title: "Super Admin",
    },
    staff: {
        color: "danger",
        title: "Super Admin",
    },
    admin_staff: {
        color: "success",
        title: "Admin/Staff",
    },
    review_board_member: {
        color: "primary",
        title: "Review Board Member",
    },
    board_of_directors: {
        color: "info",
        title: "Board of Directors",
    },
};

export const REQUEST_TYPE_DATA = {
    "request": "Architecture Review",
    "maintenance": "Maintenance",
    "feedback": "Feedback"
}

export const MODULE_ACCESS_LIST = {
    "administrator": [
        "dashboard",
        "requests",
        "member",
        "hoa",
        "property",
        "guideline",
        "acknowledgement",
        "settings",
    ],
    "hoa": [
        "dashboard",
        "requests",
        "member",
        "role",
        "property",
        "guideline",
        "acknowledgement",
        "modify_request_acknowledgement"
    ],
    "admin_staff": [
        "dashboard",
        "requests",
        "member",
        "property",
        "guideline",
        "acknowledgement",
        "modify_request_acknowledgement"
    ],
    "review_board_member": [
        "requests",
        "guideline",
    ],
    "board_of_directors": [
        "dashboard",
        "requests",
        "member",
        "property",
        "guideline",
        "acknowledgement",
        "modify_request_acknowledgement"
    ]
};

export const getAccessibleModules = (user) => {
    
    if (user.role == 'administrator' || user.role == 'hoa') {
        return MODULE_ACCESS_LIST[user.role];
    }
    const selectedHoa = user.AssignedRoles.find(
        (role) => role.hoa_id === user.selected_hoa_id
    );
    
    const allModules = selectedHoa.roles.flatMap((r) => MODULE_ACCESS_LIST[r] || []);
    return [...new Set(allModules)];
};

export const getSelectStyles: any = () => ({
    control: (provided, state) => ({
        ...provided,
        ...({
            backgroundColor: '#1c1c1c',
            borderColor: '#323248',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#323248',
            },
            color: '#916f6f',
        }),
        minHeight: '45px',
    }),
    option: (provided, state) => ({
        ...provided,
        ...({
            backgroundColor: state.isSelected || state.isFocused ? '#555' : '#1c1c1c',
            color: '#aaa',
        }),
        cursor: 'pointer',
    }),
    menu: (provided) => ({
        ...provided,
        ...({
            backgroundColor: '#1c1c1c',
        }),
        width: '100%',
    }),
    singleValue: (provided) => ({
        ...provided,
        ...({
            color: '#aaa',
        }),
    }),
    input: (provided) => ({
        ...provided,
        ...({
            // color: '#816f6f',
            color: '#aaa'
        }),
    }),
});
